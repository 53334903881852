import { useStaticQuery, graphql } from 'gatsby';

function useResponsiveImage(image, thumb = true) {

  const { listImagesSmall, listImagesLarge } = useStaticQuery(query);


  const fileName = image ? image.split('/').pop() : false;
  const allImagesSmall = listImagesSmall.edges.filter(img => img.node.childImageSharp != null );  
  const allImagesLarge = listImagesLarge.edges.filter(img => img.node.childImageSharp != null );  
  const imageSmall = allImagesSmall.find(element => element.node.childImageSharp.fluid.src.includes(fileName));
  const imageLarge =allImagesLarge.find(element => element.node.childImageSharp.fluid.src.includes(fileName));

  if(thumb){
    return imageSmall.node.childImageSharp.fluid;
  } else {
    return imageLarge.node.childImageSharp.fluid;
  }

}

export default useResponsiveImage;

const query = graphql`
  query useResponsiveImage {

    listImagesSmall: allFile {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 244, quality: 64) {
              src
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }  
    
    listImagesLarge: allFile {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 1280, quality: 100) {
              src
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }      

    
  }
`;
